
export default function () {
    const dictionary = {
        total: "Общий ЭИ",
        domain_1: "Опытный домен",
        domain_2: "Стратегический домен",
        branch_1: "Распознавание эмоций",
        branch_2: "Использование эмоций",
        branch_3: "Анализ эмоций",
        branch_4: "Управление эмоциями",
    },
        levelDictionary = [
            "Элементарный",
            "Начальный",
            "Средний",
            "Профессиональный",
            "Экспертный",
        ], branchesDictionary = {
            branch_1: "Распознавание",
            branch_2: "Использование",
            branch_3: "Понимание",
            branch_4: "Управление",
        };

    const keysToLabels = (keys) => {
        if (typeof (keys) === "string") {
            if (keys.search(",")) {
                return keys
                    .split(",")
                    .map((el) => dictionary[el])
                    .join(", ");
            } else {
                return dictionary[keys];
            }
        }
        return keys.map((key) => {
            if (key.search(",")) {
                return key
                    .split(",")
                    .map((el) => dictionary[el])
                    .join(", ");
            } else {
                return dictionary[key];
            }
        });
    };

   const colors = {
       branch_1: '#7ED956', //Распознавание эмоций
       branch_2: '#FFDE59', //Использование эмоций
       branch_3: '#5271FF', //Анализ эмоций
       branch_4: '#CC00CC', //Управление эмоциями
   }
    const barDataForAreas = (average) => {
        let newData = [];
        let maxLength = 1;
        const averageBranches = average;
        for (let branch in averageBranches) {
            let keyslength = Object.keys(averageBranches[branch]).length;
            if (keyslength > maxLength) maxLength = keyslength;
        }
        for (let i = 1; i <= maxLength; i++) {
            newData.push({ label: "", data: [], color: []});
            for (let branch in averageBranches) {
                let keys = Object.keys(averageBranches[branch]);
                if (keys.length < i) {
                    newData[i - 1].data.push(0)
                    newData[i - 1].color.push(colors[keys[i-1]])
                }
                else {
                    newData[i - 1].data.push(
                        Object.values(averageBranches[branch])[i - 1]
                    );
                    newData[i - 1].color.push(colors[keys[i-1]])
                }
            }
        }
        return newData;
    };
    // const barAverageDataForSeveralAreas = (average, keys) => {
    //     let newData = [];
    //     // let maxLength = 1;
    //     // const averageBranches = average;
    //     // for (let branch in averageBranches) {
    //     //     let keyslength = Object.keys(averageBranches[branch]).length;
    //     //     if (keyslength > maxLength) maxLength = keyslength;
    //     // }
    //     // for (let i = 1; i <= maxLength; i++) {
    //     //     newData.push({ label: "", data: [] });
    //     //     for (let key of keys) {
    //     //         if(averageBranches[key]){
    //     //             let branchKeys = Object.keys(averageBranches[key]);
    //     //             if (branchKeys.length < i) newData[i - 1].data.push(0);
    //     //             else
    //     //                 newData[i - 1].data.push(
    //     //                     Object.values(averageBranches[key])[i - 1]
    //     //                 );
    //     //         }
    //     //         else{
    //     //             newData[i - 1].data.push(0);
    //     //         }
    //     //     }

    //     // }
    //     for (let group in average) {
    //         newData.push({
    //             label: `Средние значения (${group})`,
    //             data: []
    //         })
    //         for (let key of keys) {
    //             if (average[group].average[key]) {
    //                 const branchNumbers = Object.values(average[group].average[key]);
    //                 if (branchNumbers.length > 1){

    //                 }
    //                 else newData[newData.length - 1].data.push(branchNumbers[0]);
    //             }
    //             else {
    //                 newData[newData.length - 1].data.push(0);
    //             }
    //         }
    //     }

    //     return newData;
    // };
    const barRespondentsData = (respondentsByLevels) => {
        let newData = [{
            label: "Распознавание эмоций  ",
            data: [0, 0, 0, 0, 0],
        },
        {
            label: "Использование эмоций ",
            data: [0, 0, 0, 0, 0],
        },
        {
            label: "Анализ эмоций",
            data: [0, 0, 0, 0, 0],
        },
        {
            label: "Управление эмоциями",
            data: [0, 0, 0, 0, 0],
        }];
        let respondents = respondentsByLevels
        for (let branch in respondents) {
            for (let insideBranch in respondents[branch]) {
                checkScores(newData[Number(insideBranch.slice(-1)) - 1].data, respondents[branch][insideBranch])
            }
        }
        return newData;
    }
    const checkScores = (array, selection) => {
        if (array.length != 5) {
            array.length = 5
            array.fill(0, 0, 5)
        }
        for (let value in selection) {
            if (value <= 69) array[0] += selection[value]
            else if (value <= 89) array[1] += selection[value]
            else if (value <= 109) array[2] += selection[value]
            else if (value <= 129) array[3] += selection[value]
            else array[4] += selection[value]
        }
    }


    return {
        keysToLabels,
        barDataForAreas,
        barRespondentsData,
        checkScores,
        levelDictionary,
        branchesDictionary,
        dictionary
        // barAverageDataForSeveralAreas
    }


}